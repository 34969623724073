import React, { useState } from 'react'
import { PageLayout } from '../../Components'
import { Grid } from '@mui/material'
import "./infocss.css"



export function InfosPage() {


  const [selected,setSelected]=useState(0)





  return (
    <PageLayout bgcolor={"#0e021a"} >

        <Grid style={{width:"100%",height:"100%"}} container spacing={2} >
              <Grid  item xs={12} md={3} >
                <div style={{width:"100%",height:"100%",marginTop:20,marginBottom:70}} >
                <ul className='sul'  >
                  <li onClick={()=>setSelected(0)} className='sli' >Biz Kimiz ?</li>
                  <li onClick={()=>setSelected(2)}  className='sli'>Vizyonumuz</li>
                  <li onClick={()=>setSelected(1)}  className='sli'>Misyonumuz</li>
                  <li onClick={()=>setSelected(3)}  className='sli'>Basında Biz</li>

                </ul>

                </div>
              </Grid>
              <Grid item xs={12} md={9} >
              <div style={{width:"100%",height:"100%",marginTop:20,marginBottom:70,justifyContent:"center",alignItems:"center",display:"flex"}} >

                {
                  selected===0&&
                  <div style={{width:"80%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex",padding:50,flexDirection:"column"}} >
                    <h3 style={{color:"#fff"}}>Biz Kimiz ?</h3>
                  <p style={{color:"#fff"}} > Biz insanlara doğal alanlarda doğal olarak yetiştirilmiş lavanta bahçelerimizde insanların lavantalara tekrar aşık olmasını sağlıyor. ve de elde edilen lavantalarlardan insanların gönül rahatlığıyla kullanabilecekleri
                  koku esansları, yağları, tozları ve daha bir çok çeşidi üretip insanlarla lavantalar arasındaki bağı kuruyoruz. </p>
                  <p style={{color:"#fff"}}>Amasya’nın Gümüşhacıköy İlçesinde başka mahsullerinde yetişebileceğini göstermek ve farkındalık yaratmak istedik. Bu hedefimize giden yolda bize Lavantalar yoldaşlık etmekte.</p>

                  <br/>
                  <p style={{color:"#fff"}}>
                    Lavantanın sihirli dünyasına sizleride bütün içtenliğimizle bekliyoruz
                  </p>

                  </div>
                }

{
                  selected===2&&
                  <div style={{width:"80%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex",padding:50,flexDirection:"column"}} >
                    <h3 style={{color:"#fff"}}>Vizyonumuz</h3>
                    
                  <p style={{color:"#fff"}}>
                    Bütün insanlara lavantanın sihirli dünyasının kapısını açmak
                  </p>

                  </div>
                }

{
                  selected===1&&
                  <div style={{width:"80%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex",padding:50,flexDirection:"column"}} >
                    <h3 style={{color:"#fff"}}>Misyonumuz</h3>
                    
                
                  <p style={{color:"#fff"}}>
                    Doğallık birincil şartımız olmak üzere, insanlara daha faydalı ve sağlıklı lavanta ürünlerini üretmek ve bu alanda geleceğe yön veren öncü,inevasyon sahibi bir şirket olmak
                  </p>

                  </div>
                }

              {selected===3&&
              
              <div style={{width:"100%",height:"100%",justifyContent:"center",alignItems:"center",display:"flex"}} >
                  <Grid style={{justifyContent:"center",alignItems:"center"}} container spacing={2} >
                    <Grid item >
                    <a target='_blank' href='https://www.aa.com.tr/tr/yasam/kokusundan-ve-goruntusunden-etkilenip-memleketine-lavanta-bahcesi-kurdu/2027733' >
                      <div style={{borderColor:"white"}} className='Btn_basin' > <img src='/b0.png' width={"100%"} height={"100%"} /> </div>
                      </a>
                    <a target='_blank' href='https://www.sabah.com.tr/amasya/2022/06/25/hayallerine-lavanta-ile-kavustu' >
                      <div style={{borderColor:"red"}} className='Btn_basin' > <img src='/b2.png' width={"100%"} height={"100%"} /> </div>
                      </a>

                      <a target='_blank' href='https://merzifon.bel.tr/irem-ve-sercan-hayta-cifti-belediyemize-ziyarette-bulundu' >
                      <div style={{borderColor:"rgb(0, 134, 218)"}} className='Btn_basin' > <img src='/b4.png' width={"100%"} height={"100%"} /> </div>
                      </a>
                      <a target='_blank' href='https://www.objektifamasya.com/haber/16163074/gumushacikoy-yeni-lavanta-sezonuna-hazirlaniyor' >
                      <div style={{borderColor:"red"}} className='Btn_basin' > <img src='/b6.png' width={"100%"} height={"100%"} /> </div>
                      </a>
                   
                    </Grid>
                    <Grid item >

                    <a target='_blank' href='https://www.star.com.tr/kobi/devlet-destegiyle-27-yasindaki-kendi-isinin-patronu-oldu-haber-1584495/' >
                      <div style={{borderColor:"red"}} className='Btn_basin' > <img src='/b1.png' width={"100%"} height={"100%"} /> </div>
                      </a>

                      <a target='_blank' href='https://www.gumushacikoyhaber.com/gumushacikoy-lavanta-kokacak/' >
                      <div style={{borderColor:"white"}} className='Btn_basin' > <img src='/b3.png' width={"100%"} height={"100%"} /> </div>
                      </a>

                      <a target='_blank' href='https://www.niksardanismend.com/supurge-mi-dikiyorsun-diyenlere-inat-lavanta-yetistirdi/15462/' >
                      <div style={{borderColor:"red"}} className='Btn_basin' > <img src='/b5.png' width={"100%"} height={"100%"} /> </div>
                      </a>

                      <a target='_blank' href='https://www.yesilirmak.org.tr/yhkb-haber-319.html' >
                      <div style={{borderColor:"aqua"}} className='Btn_basin' > <img src='/b7.png' width={"100%"} height={"100%"} /> </div>
                      </a>

                    

                    </Grid>

                  </Grid>

              </div>   
                
                
                }


              </div>






              </Grid>

        </Grid>

    </PageLayout>
  )
}
