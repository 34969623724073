import React from 'react';
import './App.css';
import { Element } from "react-scroll";
import { MainLayout } from './Components';
import { HomePage,ContactPage,GalleryPage,InfosPage,LavantaPage,ShopingPage} from './Pages';


const Pages=[
  {name:"HomePage",page:<HomePage />},
  {name:"LavantaPage",page:<LavantaPage />},
  {name:"InfosPage",page:<InfosPage />},
  {name:"GalleryPage",page:<GalleryPage />},
  {name:"ShopingPage",page:<ShopingPage />},
  {name:"ContactPage",page:<ContactPage />},


]

function App() {
  return (
    <div className="App">

      <MainLayout >
     {
      Pages.map((d,i)=>{
        return(
          <React.Fragment key={i} >
            <Element name={d.name} > {d.page} </Element>
          </React.Fragment>
        )
      })
     }
     </MainLayout> 
  
    </div>
  );
}

export default App;
