import React from 'react'
import { Appbar } from '../Views/Appbar/Appbar'

const date=new Date()
const browserInfo = window.navigator;

const userAgent = browserInfo.userAgent;

const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
console.log(isSafari)

export  function MainLayout( {children}) {
  return (
    <div style={{
        width:"100vw",height:"auto",
   }} >
         <div style={{
            width:"100%",height:"100vh",
            backgroundAttachment:"fixed",
            backgroundPosition:isSafari?"center top":"center",
            backgroundRepeat:"no-repeat",
            backgroundSize:isSafari?"":"cover",
            backgroundImage:"url('/ilkiremm.gif')",
            WebkitBackgroundSize:isSafari?"":"cover",
            
            // filter:"grayscale(40%)"
         }} >
        <Appbar />
        

         </div>
         <div style={{position:"absolute",top:0,left:0,zIndex:2,width:"100%"}} >
        {children}
          <div style={{width:"100vw",height:30,backgroundColor:"rgba(0,0,0,0.7)",display:"flex",justifyContent:"center",alignItems:"center",position:"fixed",bottom:0,left:0,zIndex:9999,
        color:"#ccc",fontSize:12}} >
                Copyright © {date.getFullYear()} Relteco Tüm Hakları Saklıdır 
          </div>
         </div>

    </div>
  )
}
//background-image: url("/public/ilkiremm.png");