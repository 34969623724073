import React from 'react'
import { PageLayout } from '../../Components'
import { Slide } from 'react-slideshow-image';
import './slider.css';
import 'react-slideshow-image/dist/styles.css';
const browserInfo = window.navigator;

const userAgent = browserInfo.userAgent;

const isSafari = /^((?!chrome|android).)*safari/i.test(userAgent);
console.log(isSafari)

export  function GalleryPage() {

  return (
    <PageLayout h='100vh' bgcolor={"rgba(132, 61, 196,1)"} >
    <Slide duration={1900}  >
       {
        Array(29).fill().map((d,i)=>{
          return(
            <React.Fragment key={i} >
                <div className="each-slide-effect">
                <div  style={{ backgroundImage: `url('/g${i}.jpeg')`,
                backgroundPosition:isSafari?"":"center",
                backgroundAttachment:isSafari?"":"fixed",
                backgroundSize:isSafari?"":"cover", 
                WebkitBackgroundSize:isSafari?"":"cover" 
                }}>             
                </div>
            </div>
            </React.Fragment>
          )
        })
       }
        </Slide>
    </PageLayout>
  )
}
