import React from 'react'
import { PageLayout } from '../../Components'
import { Box, Grid } from '@mui/material'
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import "./shop.css"


export function ShopingPage() {

  const urunler = [
    { name: "Lavanta Oda kokusu", img: "p4.jpeg", text: "Evinizi lavanta bahçesine çevirin. Ferahlatıcı lavanta kokusunu sonuna kadar hissedin." },
    { name: "Lavanta Yağı", img: "p3.jpeg", text: "Lavantanın şifa gücüne şahit olun. ve sağlıklı günlerinizde sizlere yoldaşlık etsin " },
    { name: "Lavanta Kolanyası", img: "p2.jpeg", text: "Ellerinizi lavanta kokusu ile arındırın. Bütün teninizi lavantanın kokusu sarsın " },
    { name: "Lavanta Kesesi", img: "p1.jpeg", text: "Lavanta kesesi ile ortamınızı nemden ve kötü kokulardan arındırın." },
    { name: "Lavanta Sabunu", img: "p0.jpeg", text: "Lavanta sabunu ile yüzünüzdeki akneleri yok eder , ve teninizde aydın bir görünüm sağlar" },

  ]


  return (
    <PageLayout  bgcolor={"#D2E0FB"} >
      <Box sx={{ width: "100%", height: 80 }} ></Box>
      <Grid sx={{justifyContent:"center",p:2,mb:2,mt:2}} container spacing={2}>
        {urunler.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <Grid sx={{alignItems:"center",mb:2}} item xs={12} sm={urunler.length/1.2} md={urunler.length/2.1} >
                <Card sx={{minHeight:430 }}>
                  <CardMedia
                    sx={{ height: 240 }}
                    image={d.img}
                    title="green iguana"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {d.name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {d.text}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    <div className='btn_price'  >HEMEN SATIN AL  </div>
                  </CardActions>
                </Card>

              </Grid>
            </React.Fragment>
          )
        })}
      </Grid>
    </PageLayout>
  )
}
