import React from 'react'
import { LogoButtons, PageLayout } from '../../Components'
import { MapContainer, Marker, Popup, TileLayer, useMap } from 'react-leaflet'
import { Grid } from '@mui/material';
import { FaLocationDot,FaPhone,FaGlobe } from "react-icons/fa6";
export function ContactPage() {

  const map = {
    center:[ 
      40.8613863,
      35.2449688
    ],
    zoom: 13
  };

  return (
    <PageLayout h='70vh' bgcolor={"#0e021a"} >


      <Grid sx={{ height: "100%" }} container spacing={2}>
        <Grid item xs={12} md={6}>
       
        <MapContainer style={{ height:"100%", width: '100%',minHeight:150 }} center={map.center} zoom={map.zoom} scrollWheelZoom={false}>
              <TileLayer
                url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
              />
              <Marker position={map.center}>
                <Popup>
                Konumu  <a target='_blank' href="https://www.google.com/maps/place/%C4%B0lkirem+Lavanta+Bah%C3%A7esi/@40.8613863,35.2449688,15z/data=!4m6!3m5!1s0x4086e352b6b556c7:0x28674df1c9d35e04!8m2!3d40.8613863!4d35.2449688!16s%2Fg%2F11y1d37ypp?entry=ttu">Haritalar</a> Uygulamasında Aç
                </Popup>
              </Marker>
            </MapContainer>
            <div style={{width:"100%",height:30,backgroundColor:"#0e021a",position:"absolute",zIndex:9999,bottom:0}} ></div>


        </Grid>

        <Grid item xs={12} md={6}>

          <div style={{height:"100%", width: '100%' ,display:"flex",justifyContent:"center",color:"#ccc",flexDirection:"column",alignItems:"center"}} >
            <h3 ><FaLocationDot /> Adres:</h3>
            <p>Adatepe, Eslemez Yolu no 21,<br /> 05700 Gümüşhacıköy/Amasya</p>
            <h3><FaPhone />  Telefon:</h3>
            <p>0 546 455 47 36 </p>
            <h3><FaGlobe /> Sosyal Medya:</h3>
            <LogoButtons justifyContent="flex-start" fs={40} />

          </div>

        </Grid>
      </Grid>
    </PageLayout>
  )
}
