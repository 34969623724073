import React from 'react'
import "./appbar.css"
import { AppbarMenu } from '../../Elements/Menus/AppBarMenu/AppbarMenu'
import { LogoView } from '../LogoView/LogoView'
import { LogoButtons } from '../LogoButtons/LogoButtons'
import { NavButton } from '../../Elements/Buttons/NavButton/NavButton'

export function Appbar() {
  return (
    <div className='appbar' >

        <div id='appbar_logo' className='hdiv' >
            <LogoView />
        </div>
        <div id='appbar_menu_resp' className='hdiv'>
          <NavButton />
        </div>
        <div id='appbar_menu' className='hdiv' >
            <AppbarMenu />
        </div>
        <div id='social_menu' className='hdiv' >
            <LogoButtons />
        </div>


    </div>
  )
}
