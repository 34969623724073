import React, { useEffect, useState } from 'react'
import { PageLayout } from '../../Components'
import "./Lavanta.css"

import { motion } from "framer-motion"
import { Avatar, Box, Button, Grid, IconButton } from '@mui/material';
import { FaAngleLeft, FaAngleRight } from "react-icons/fa6";
import { AnimatedText } from '../../Components';

// function Lcard({ trns = 100 }) {

//   const [f, setf] = useState(0)
//   const variants = {
//     open: { opacity: 1, x: 0 },
//     closed: { opacity: 0, x: trns },
//   }




//   useEffect(() => {

//     window.addEventListener('scroll', () => {
//       // console.log(window.scrollY);
//       setf(window.scrollY)
//     });
//   }, [])
//   return (
//     <motion.nav
//       animate={f >= window.innerHeight / 2 && f <= window.innerWidth / 1.4 ? "open" : "closed"}
//       variants={variants}
//     // transition={{ ease: "easeOut", duration: 2 }}
//     >
//       <div style={{ width: 300, height: 300, backgroundColor: "red" }} >

//       </div>

//     </motion.nav>
//   )
// }

export function LavantaPage({id}) {

  const lavantas = [
    {id:0, img: "/l4.jpg", title: "LAVANTA NEDİR?", text: "Lavanta, ballıbabagiller familyasına ait olan çok yıllık bir bitki türünü oluşturuyor. Mor ve mavimsi renklerde çiçekler açan lavanta bitkisinin kullanımı 3 bin yıl öncesine dayanıyor. Bir Akdeniz bitkisi olan lavanta, ülkemizde en çok Antalya, Muğla, Isparta, Mersin, İzmir ve İstanbul şehirlerinde yetiştiriliyor. Dünya genelinde ise Fransa, Bulgaristan, Çin ve Rusya lavanta yetiştiriciliğinde ilk sıralarda yer alıyor. Geniş bir kullanım alanına sahip olan lavanta, özellikle de son yıllarda ülkemizin yükselen ekonomik değerlerinden birini oluşturuyor." },
    {id:1, img: "l1.jpg", title: "Lavanta Faydaları Nelerdir?", text: "Lavanta yağ olarak deri üzerinde kullanıldığında hafif dereceli yanıkları iyileştirir. Aynı zamanda akne oluşumunu da engeller.    Yağının diğer faydalarından biri de aromatik olarak kullanıldığında sakinleştirdiği ve uykuya geçişi hızlandırdığıdır.   Saç oluşumunu destekleyerek mevcut saçların kalınlaşmasın yardımcı olur. Lavanta çayı bağırsak sorunlarına iyi gelerek mide içinde yatıştırıcı etkiye sahiptir. Alın, boyun ve ense masajında kullanılan lavanta yağı baş ağrısına iyi gelir.  Faydaları arasında onlarcasından bazıları yukarıdaki gibidir. " },
    {id:2, img: "l2.jpg", title: "Lavanta Nasıl Yetiştirilir?", text: "Lavanta kokusu yanında yararları ve görünüşüyle de yetiştirmeyi düşünebileceği bitki türlerinden biridir. Lavanta nerede yetişir diye düşünüldüğünde de sadece akla meşhur tarlalar geliyor. Oysa lavanta tarla harici saksıda da yetiştirebileceğiniz bitkilerden. Birkaç adımda lavanta yetiştirmek için:" },
    {id:3, img: "l3.jpg", title: "Lavanta Ne Zaman Açar?", text: "Ekimi kolay, bakımı zahmetsiz bir bitkidir. Bunlara ek olarak hasat dönemi de bir o kadar özen ister. Lavanta ekimi için çoğunlukla mayıs ayı tercih edilir. Bu dönemin ardından geçen iki aylık sürede bitkiler büyür. Lavanta hasat dönemi temmuz ayında başlayarak ağustos ortalarına kadar devam eder. Fark etmeksizin bu dönemler hasat için idealdir. Kolonyadan kreme, parfüme ve koku keselerine kadar kendine yer bulan lavanta herkesin hayatında kendine yer bulabilir. " },
    {id:4, img: "lavanta2.jpg", title: "Lavanta Anlamı Nedir?", text: "Endüstriyel kullanımlarının ve harika manzaralar yaratmanın ardında derin anlamlara sahiptir. Çiçekler kişilerin romantik yanlarına dokunduğu gibi okumasını bilenler için duygusal ifadelerin sessiz halidir. Lavanta anlamlarından bazıları:Beyaz lavanta kırmızılardan daha fazla bilinen bir türdür. Şık duruşuyla anlamıysa kabul olmasını istediğiniz bir dileğin gerçekleşmesini ifade eder. Bu yüzden dileği kabul olmuş isteklerine kavuşmuş birine hediye edebileceğiniz çiçek türlerindendir.  Mor renk anlamıyla da lavantada vücut bulmuş haliyle de asaleti temsil eder. Bu konuda siyah lacivert gibi renkler akla gelse de mor renk asilliğin göstergelerindendir. Mor lavanta da daha üst amaçları daha büyük makamları ve istekleri temsil etmektedir. Bir bitkiden daha fazlasını ifade eden lavanta anlamıyla da gönüllere taht kurar. " },

  ]
  const [selectedID, setSelectedID] = useState(0)
  const [selected, setSelected] = useState(lavantas[selectedID])





  const Prev = () => {
    if (selectedID < lavantas.length - 1) {
      setSelectedID(selectedID + 1)
      setSelected(lavantas[selectedID + 1])
    } else {
      setSelectedID(0)
      setSelected(lavantas[0])
    }

  }

  const Back = () => {
    if (selectedID !== 0) {
      setSelectedID(selectedID - 1)
      setSelected(lavantas[selectedID - 1])

    } else {
      setSelectedID(lavantas.length - 1)
      setSelected(lavantas[lavantas.length - 1])
    }
  }

  


  return (
    <PageLayout bgcolor='#D2E0FB'  >
      <div style={{position:"relative"}} >
      <Box sx={{ width: "100%", height: 80 }} ></Box>

      <Box sx={{ width: "100%", height: 55 }} ></Box>

      <div className='Ltitle' >
        <h1 style={{ backgroundColor: "#D2E0FB" }} className='Lh1' >LAVANTA</h1>
      </div>
      <Box sx={{ width: "100%", height: 50 }} ></Box>
    

      <Grid sx={{ height: "80%",mt:2 }} container spacing={2}>

        <Grid item xs={12} md={6}  >

          <div style={{  display: "flex", justifyContent: "center", alignItems: "center" }} >

            <Avatar sx={{ width: 350, height: 350 }} src={selected.img} />


          </div>
        </Grid>
        <Grid item xs={12} md={6}>
          <div style={{  width: "100%", display: "flex", justifyContent: "center", alignItems: "center",flexDirection:"column",textAlign:"center" }} >

            <h2 style={{padding:10}} > {selected.title} </h2>
            <br />
            <p style={{paddingLeft:50,paddingRight:50}} > {selected.text} </p>

          </div>
        </Grid>

      <div className='icon_btn_area' >

        <div onClick={() => { Back() }}  >  <FaAngleLeft className='iconBtn' /> </div>

          {Array(lavantas.length).fill().map((d,i)=>{
            return(
              <React.Fragment key={i}>
                <div style={{width:10,height:10,backgroundColor:selectedID===i?"#000":"#888",borderRadius:"50%",margin:3}} ></div>
              </React.Fragment>
            )
          })}


        <div onClick={() => { Prev() }}  > <FaAngleRight className='iconBtn' /> </div>


      </div>
      </Grid>
      </div>
    </PageLayout>
  )
}
