import React from 'react'
import { FaFacebook ,FaInstagram} from "react-icons/fa6";
import "./LogoButtons.css"


export  function LogoButtons({fs,justifyContent="center"}) {
  return (
    <div style={{
        display:"flex",
        flexDirection:"row",
        justifyContent:justifyContent,
        alignItems:"center"
    }} >
        <div  onClick={()=> window.open('https://www.facebook.com/ilkirem.lavanta', '_blank', 'noreferrer')} >
        <FaFacebook size={fs}  className='logobtn' />
        </div>

        <div  onClick={()=> window.open('https://www.instagram.com/ilkiremlavanta/', '_blank', 'noreferrer')} >
        <FaInstagram size={fs} className='logobtn'  />
        </div>

    </div>
  )
}
