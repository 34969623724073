import React, { useState } from 'react'
import "./NavButton.css"
import { LeftMenu } from '../../Menus/LeftMenu/LeftMenu'
import { FaBars } from "react-icons/fa6";
export  function NavButton() {

  const [leftm,setleftm]=useState(false)



  
  return (
    <div>
      <LeftMenu closeLeftMenu={()=>{setleftm(false)}} display={leftm} />

    <div onClick={()=>{setleftm(true)}} className='navmenu' >
            <FaBars fontSize={30} />

    </div>
    </div>
  )
}
