import React from 'react'
import "./AppbarMenu.css"
import { Link } from "react-scroll";

export  function AppbarMenu() {

const Menus=[
  {name:"İlkirem",id:"HomePage"},
  {name:"Lavanta",id:"LavantaPage"},
  {name:"Hakkımızda",id:"InfosPage"},
  {name:"Galeri",id:"GalleryPage"},
  {name:"Ürünlerimiz",id:"ShopingPage"},
  {name:"Bize Ulaşın",id:"ContactPage"},

]


  return (
    <div className='appbarmenu' >
      {
        Menus.map((d,i)=>{
          return(
            <React.Fragment key={i}>
              <Link className='appbarmenu_item' activeClass="active" smooth spy to={d.id}> {d.name} </Link>
            </React.Fragment>
          )
        })
      }
    </div>
  )
}
