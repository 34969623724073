import React, { useRef } from 'react'
import { motion } from "framer-motion"
import "./LeftMenu.css"
import { LogoView } from '../../../Views/LogoView/LogoView'
import { LogoButtons } from '../../../Views/LogoButtons/LogoButtons'
import { FaXmark } from "react-icons/fa6";
import { Link } from 'react-scroll'

export function LeftMenu({display=false,closeLeftMenu}) {
  const variants = {
    open: { opacity: 1, x: 0 },
    closed: { opacity: 0, x: "-100%" },
  }


  const Menus=[
    {name:"İlkirem",id:"HomePage"},
    {name:"Lavanta",id:"LavantaPage"},
    {name:"Hakkımızda",id:"InfosPage"},
    {name:"Galeri",id:"GalleryPage"},
    {name:"Ürünlerimiz",id:"ShopingPage"},
    {name:"Bize Ulaşın",id:"ContactPage"},
  
  ]

  return (
    <motion.nav 
     animate={display ? "open" : "closed"}
    variants={variants}
    className='leftbarmenu' >
      <div className='leftbarmenu_Bar' >
        <LogoView />
        <div onClick={closeLeftMenu} className='navmen' >
        <FaXmark fontSize={35} />
    </div>
      </div>
      {
        Menus.map((d,i)=>{
          return(
            <React.Fragment key={i} >
            <div onMouseUp={closeLeftMenu} className='leftbarmenu_item' >
              <Link   activeClass="active" smooth spy to={d.id}> {d.name} </Link>
            </div>
            </React.Fragment>
          )
        })
      }

    <div style={{width:"100%",height:50}} ></div>
<LogoButtons fs={40} />

</motion.nav>
  )
}
