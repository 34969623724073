import React from 'react'
import { AnimatedText, PageLayout } from '../../Components'
import { Link } from 'react-scroll'
import "./homestyle.css"
export  function HomePage() {
  return (
    <PageLayout h='100vh' >

      <div style={{width:"100%",height:"100%",display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column",textAlign:"center"}} >

        <div style={{fontWeight:"bold",fontSize:68,fontFamily:"'Nothing You Could Do', cursive",color:"#fff" ,userSelect:"none"}} > İlkirem Lavanta   </div>
        <div style={{fontWeight:"bold",fontSize:38,fontFamily:"'Nothing You Could Do', cursive",color:"white",userSelect:"none" }} > Lavanta'nın saklı bahçesine açılan kapı  </div>

         {/* <AnimatedText fm={"'Nothing You Could Do', cursive"} fw={"bold"} fs={"68px"} text={"İlkirem Lavanta"} /> */}
    
         {/* <AnimatedText  fs={"38px"} text={"Lavanta'nın saklı bahçesine açılan kapı"} /> */}

         <br/>
         <br />
         <br />
         <br />

          <div>
          <Link className='btn_kesif' activeClass="active" smooth spy to={"ShopingPage"}> Hemen Keşfet </Link>
          </div>
      </div>

    </PageLayout>
  )
}
